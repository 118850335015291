<template>
    <v-card outlined>
        <div
            id="mandateChart"
            ref="agriEcoDev"
            :style="
                'height:' + (height - 60).toString() + 'px;' + 'width: 100%'
            "
        ></div>
    </v-card>
</template>
<script>
import * as echarts from "echarts";
import { mapState, mapMutations } from "vuex";

export default {
    props: ["isAscending", "chartOption", "amountOption", "height"],
    data() {
        return {
            noDataMessage: "No Data Available",
            chart: Object,
            option: {
                title: {
                    text: "Loading...",
                    left: "center",
                    top: "center",
                    textStyle: { color: "gray", fontSize: "30px" },
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        // 坐标轴指示器，坐标轴触发有效
                        type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
                    },
                },
                grid: {
                    left: 100,
                    right: 60,
                    top: 40,
                    bottom: 30,
                    borderWidth: 0,
                },
                xAxis: {
                    type: "value",
                    position: "top",
                    axisLabel: {
                        show: true,
                        color: "#fff",
                        formatter: (data) => {
                            return data;
                        },
                    },
                    splitLine: { lineStyle: { type: "dashed" }, show: false },
                    axisLabel: {
                        color: "#fff",
                        formatter: function (value, index) {
                            let data = value;

                            if (Math.abs(value) > 1000000) {
                                data = (value / 1000000).toFixed(0) + "M";
                                return data;
                            } else if (Math.abs(value) >= 1000) {
                                data = (value / 1000).toFixed(0) + "K";
                                return data;
                            } else if (Math.abs(value) < 1000) {
                                data = value;
                                return data;
                            }
                            return value;
                        },
                    },
                },
                yAxis: {
                    type: "category",
                    offset: 30,
                    axisLine: { show: false },
                    axisLabel: { show: true, color: "#fff", interval: 0 },
                    axisTick: { show: false },
                    splitLine: { show: false },
                    data: this.yName,
                },
                series: [
                    {
                        name: "Net Volume",
                        type: "bar",
                        stack: "Total",
                        label: {
                            show: true,
                            formatter: function (a, b, c) {
                                if (Math.abs(a.data) > 1000000) {
                                    return (
                                        (a.data / 1000000)
                                            .toFixed(2)
                                            .toString() +
                                        " M" +
                                        (a.data.chartOption === "PNL"
                                            ? ""
                                            : " / " + a.data.pnl)
                                    );
                                } else if (Math.abs(a.data) > 1000) {
                                    return (
                                        (a.data / 1000).toFixed(2).toString() +
                                        " K" +
                                        (a.data.chartOption === "PNL"
                                            ? ""
                                            : " / " + a.data.pnl)
                                    );
                                } else {
                                    return (
                                        a.data.value +
                                        (a.data.chartOption === "PNL"
                                            ? ""
                                            : " / " + a.data.pnl)
                                    );
                                }
                            },
                            color: "#fff",
                        },
                        itemStyle: {
                            color: (data) => {
                                if (data.value > 0) return "#2c69ab";
                                if (data.value < 0) return "#c82c20";
                            },
                        },
                        data: this.yValue,
                    },
                ],
            },
        };
    },
    watch: {
        yData(nv) {
            if (nv.length === 0) {
                this.option.title.text = "No Data Available";
            } else {
                this.option.title.text = "";
            }

            if (!this.isAscending) {
                nv = nv.reverse();
            }
            if (this.amountOption === "Top 10" && nv.length > 10) {
                const first5 = nv.slice(0, 5);
                const last5 = nv.slice(-5);
                nv = [...first5, ...last5];
            }

            if (this.chartOption === "Volume") {
                this.option.series[0].name = "Net Volume";
                this.option.series[0].data = nv.map((value) => {
                    return {
                        value: value.volume,
                        pnl: value.pnl,
                        chartOption: this.chartOption,
                    };
                });
                // this.option.title.text = "Net Volume";
            } else if (this.chartOption === "PNL") {
                this.option.series[0].name = "PNL";
                this.option.series[0].data = nv.map((value) => {
                    return {
                        value: value.pnl,
                        chartOption: this.chartOption,
                    };
                });
                // this.option.title.text = "PNL";
            }
            this.option.yAxis.data = nv.map((value) => value.name);
            this.processData();
        },
        height(nv) {
            this.$nextTick(() => {
                this.chart.resize({
                    height: this.$refs.agriEcoDev.clientHeight,
                });
            });
        },
    },
    computed: {
        ...mapState("Mandate", [
            "yName",
            "yValue",
            "profitThreshold",
            "volumeThreshold",
        ]),
        /**
         * Extract component width and height
         * @return  {[type]}  [return description]
         */
        //join yName and cleaned yValue and return the sorted joinedY data
        yData() {
            const joinedY = this.yName
                .map((value, index) => {
                    if (
                        this.chartOption === "Volume" &&
                        Math.abs(this.yValue[index].volume) >
                            this.volumeThreshold
                    ) {
                        return {
                            name: value,
                            volume: this.yValue[index].volume.toFixed(2),
                            pnl: this.yValue[index].pnl,
                        };
                    } else if (
                        this.chartOption === "PNL" &&
                        Math.abs(this.yValue[index].pnl) > this.profitThreshold
                    ) {
                        return {
                            name: value,
                            volume: this.yValue[index].volume.toFixed(2),
                            pnl: this.yValue[index].pnl,
                        };
                    }
                })
                .filter((item) => item !== undefined);

            if (this.chartOption === "Volume") {
                const cleanedData = this.sortArray(joinedY, "volume");
                return cleanedData;
            } else if (this.chartOption === "PNL") {
                const cleanedData = this.sortArray(joinedY, "pnl");
                return cleanedData;
            }
        },
    },
    methods: {
        ...mapMutations("Mandate", ["UPDATE_Y_VALUE", "UPDATE_Y_NAME"]),
        /**
         * Sort object
         * @param   {[type]}  data  [data description]
         * @return  {[type]}        [return description]
         */
        sortArray(data, type) {
            data.sort((a, b) => b[type] - a[type]);
            return data;
        },
        /**
         * Dealing up coming exposure data, generate x & y data
         * @return  {[type]}  [return description]
         */
        processData() {
            this.chart.clear();
            this.chart.setOption(this.option);
            const self = this;
            if (this.yData.length <= 3) {
                self.chart.resize({
                    height:
                        this.$refs.agriEcoDev.clientHeight *
                        (0.3 + this.yData.length * 0.15),
                });
            } else {
                self.chart.resize({
                    height: this.$refs.agriEcoDev.clientHeight,
                });
            }
        },
    },
    mounted() {
        this.chart = echarts.init(this.$refs.agriEcoDev);
        this.processData();
    },
    destroyed() {
        this.UPDATE_Y_VALUE([]);
        this.UPDATE_Y_NAME([]);
    },
};
</script>
