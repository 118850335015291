<template>
    <div class="pb-5">
        <v-row class="ma-0" id="symbolTabTimeSelection">
            <v-col cols="5" class="pt-0">
                <v-datetime-picker
                    label="Target Datetime (MT4 server time)"
                    v-model="selectedTime"
                    hide-details
                    dense
                    :datePickerProps="{ max: getMt4Date() }"
                ></v-datetime-picker>
            </v-col>
            <v-col cols="3" class="pt-0">
                <v-btn
                    small
                    v-on:click="fetchSnapshotRecord()"
                    color="primary"
                    class="mt-5"
                    :loading="loading"
                    :disabled="isFetchingDisabled"
                    >Fetch</v-btn
                >
            </v-col>
        </v-row>
        <v-data-table
            :headers="snapshotHeader"
            :loading="loading"
            :items="computedSnapshotData"
            :header-props="{ sortIcon: null }"
            :mobile-breakpoint="0"
            hide-default-footer
            disable-sort
            id="symbolTabSnapshotTable"
            no-data-text="No snapshot file found for the selected datetime, please choose another time."
        >
            <template v-slot:item.long="{ item }">
                <span v-if="item.long.includes('u')"
                    >{{ item.long.replace("u", "")
                    }}<v-icon color="green">mdi-arrow-up-thin</v-icon></span
                >
                <span v-else-if="item.long.includes('d')"
                    >{{ item.long.replace("d", "")
                    }}<v-icon color="red">mdi-arrow-down-thin</v-icon></span
                >
                <span v-else>{{ item.long }}</span>
            </template>
            <template v-slot:item.short="{ item }">
                <span v-if="item.short.includes('u')"
                    >{{ item.short.replace("u", "")
                    }}<v-icon color="green">mdi-arrow-up-thin</v-icon></span
                >
                <span v-else-if="item.short.includes('d')"
                    >{{ item.short.replace("d", "")
                    }}<v-icon color="red">mdi-arrow-down-thin</v-icon></span
                >
                <span v-else>{{ item.short }}</span>
            </template>
            <template v-slot:item.net="{ item }">
                <span v-if="item.net.includes('u')"
                    >{{ item.net.replace("u", "")
                    }}<v-icon color="green">mdi-arrow-up-thin</v-icon></span
                >
                <span v-else-if="item.net.includes('d')">
                    {{ item.net.replace("d", "") }}
                    <v-icon color="red">mdi-arrow-down-thin</v-icon>
                </span>
                <span v-else>{{ item.net }}</span>
            </template>
            <template v-slot:item.realized="{ item }">
                <span v-if="item.realized.includes('u')"
                    >{{ item.realized.replace("u", "")
                    }}<v-icon color="green">mdi-arrow-up-thin</v-icon></span
                >
                <span v-else-if="item.realized.includes('d')"
                    >{{ item.realized.replace("d", "")
                    }}<v-icon color="red">mdi-arrow-down-thin</v-icon></span
                >
                <span v-else>{{ item.realized }}</span>
            </template>
            <template v-slot:item.unrealized="{ item }">
                <span v-if="item.unrealized.includes('u')"
                    >{{ item.unrealized.replace("u", "")
                    }}<v-icon color="green">mdi-arrow-up-thin</v-icon></span
                >
                <span v-else-if="item.unrealized.includes('d')"
                    >{{ item.unrealized.replace("d", "")
                    }}<v-icon color="red">mdi-arrow-down-thin</v-icon></span
                >
                <span v-else>{{ item.unrealized }}</span>
            </template>
            <template v-slot:item.dailyNet="{ item }">
                <span v-if="item.dailyNet.includes('u')"
                    >{{ item.dailyNet.replace("u", "")
                    }}<v-icon color="green">mdi-arrow-up-thin</v-icon></span
                >
                <span v-else-if="item.dailyNet.includes('d')"
                    >{{ item.dailyNet.replace("d", "")
                    }}<v-icon color="red">mdi-arrow-down-thin</v-icon></span
                >
                <span v-else>{{ item.dailyNet }}</span>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { helper } from "@components/mixins/helper";
import {
    getSymbolSnapshotData,
    getLoginSnapshotData,
} from "@services/snapshot";
import { mapState } from "vuex";

export default {
    mixins: [helper],
    name: "SnapshotTab",
    props: {
        target: null,
        symbol: null,
        book: null,
        login: null,
        server: null,
    },
    data() {
        return {
            selectedTime: null,
            loading: false,
            snapshotHeader: [
                {
                    text: "Aspect",
                    value: "aspect",
                    width: 40,
                    align: "left",
                },
                {
                    text: "Long",
                    value: "long",
                    width: 80,
                    align: "left",
                },
                {
                    text: "Short",
                    value: "short",
                    width: 80,
                    align: "left",
                },
                {
                    text: "Net Lot",
                    value: "net",
                    width: 80,
                    align: "left",
                },
                {
                    text: "Realized",
                    value: "realized",
                    width: 80,
                    align: "left",
                },
                {
                    text: "Unrealized",
                    value: "unrealized",
                    width: 80,
                    align: "left",
                },
                {
                    text: "Daily Net",
                    value: "dailyNet",
                    width: 80,
                    align: "left",
                },
            ],
            snapshotData: null,
        };
    },
    watch: {
        symbol() {
            this.snapshotData = null;
        },
        login() {
            this.snapshotData = null;
        },
    },
    computed: {
        ...mapState("Home", ["symbolSnapshotData", "loginSnapshotData"]),
        isFetchingDisabled() {
            return !this.selectedTime;
        },
        computedSnapshotData() {
            if (!!this.snapshotData) {
                return [
                    {
                        aspect: "Previous",
                        long: this.snapshotData?.long.toFixed(2),
                        short: this.snapshotData?.short.toFixed(2),
                        net: this.snapshotData?.net.toFixed(2),
                        realized: this.numberWithCommas(
                            this.snapshotData?.realized.toFixed(2)
                        ),
                        unrealized: this.numberWithCommas(
                            this.snapshotData?.unrealized.toFixed(2)
                        ),
                        dailyNet: this.numberWithCommas(
                            this.snapshotData?.dailyNet.toFixed(2)
                        ),
                    },
                    {
                        aspect: "Current",
                        long:
                            this.target === "symbol"
                                ? this.symbolSnapshotData?.long.toFixed(2)
                                : this.loginSnapshotData?.long.toFixed(2),
                        short:
                            this.target === "symbol"
                                ? this.symbolSnapshotData?.short.toFixed(2)
                                : this.loginSnapshotData?.short.toFixed(2),
                        net:
                            this.target === "symbol"
                                ? this.symbolSnapshotData?.net.toFixed(2)
                                : this.loginSnapshotData?.net.toFixed(2),
                        realized:
                            this.target === "symbol"
                                ? this.numberWithCommas(
                                      this.symbolSnapshotData?.realized.toFixed(
                                          2
                                      )
                                  )
                                : this.numberWithCommas(
                                      this.loginSnapshotData?.realized.toFixed(
                                          2
                                      )
                                  ),
                        unrealized:
                            this.target === "symbol"
                                ? this.numberWithCommas(
                                      this.symbolSnapshotData?.unrealized.toFixed(
                                          2
                                      )
                                  )
                                : this.numberWithCommas(
                                      this.loginSnapshotData?.unrealized.toFixed(
                                          2
                                      )
                                  ),
                        dailyNet:
                            this.target === "symbol"
                                ? this.numberWithCommas(
                                      this.symbolSnapshotData?.dailyNet.toFixed(
                                          2
                                      )
                                  )
                                : this.numberWithCommas(
                                      this.loginSnapshotData?.dailyNet.toFixed(
                                          2
                                      )
                                  ),
                    },
                    {
                        aspect: "Variation(Lot)",
                        long: this.variationCalculator("long", "lot"),
                        short: this.variationCalculator("short", "lot"),
                        net: this.variationCalculator("net", "lot"),
                        realized: this.numberWithCommas(
                            this.variationCalculator("realized", "lot")
                        ),
                        unrealized: this.numberWithCommas(
                            this.variationCalculator("unrealized", "lot")
                        ),
                        dailyNet: this.numberWithCommas(
                            this.variationCalculator("dailyNet", "lot")
                        ),
                    },
                    {
                        aspect: "Variation(%)",
                        long: this.variationCalculator("long", "%"),
                        short: this.variationCalculator("short", "%"),
                        net: this.variationCalculator("net", "%"),
                        realized: this.variationCalculator("realized", "%"),
                        unrealized: this.variationCalculator("unrealized", "%"),
                        dailyNet: this.variationCalculator("dailyNet", "%"),
                    },
                ];
            } else {
                return [];
            }
        },
    },
    methods: {
        fetchSnapshotRecord() {
            this.loading = true;
            this.snapshotData = null;
            if (this.target === "symbol") {
                const params = {
                    selectedTime: this.formatDateTime(this.selectedTime),
                    selectedSymbol: this.symbol,
                    selectedBook: this.book,
                };

                getSymbolSnapshotData(params)
                    .then(({ data }) => {
                        this.snapshotData = data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        console.log(err);
                        this.loading = false;
                    });
            } else if (this.target === "login") {
                const params = {
                    selectedTime: this.formatDateTime(this.selectedTime),
                    selectedSymbol: this.symbol,
                    selectedBook: this.book,
                    selectedLogin: this.login,
                    selectedServer: this.server,
                };

                getLoginSnapshotData(params)
                    .then(({ data }) => {
                        this.snapshotData = data;
                        this.loading = false;
                    })
                    .catch((err) => {
                        console.log(err);
                        this.loading = false;
                    });
            }
        },
        getMt4Date() {
            return this.getMT4Date();
        },
        variationCalculator(type, unit) {
            console.log(this.symbolSnapshotData);
            const symbol = {
                long: 80.65,
                short: 106.85,
                net: -26.2,
                realized: 15242.430000000018,
                unrealized: -20831.89,
                dailyNet: -5589.46,
            };
            const login = {
                long: 0,
                short: 0.01,
                net: -0.01,
                realized: 0,
                unrealized: 0.64,
                dailyNet: 0.64,
            };
            const variation =
                (this.target === "symbol" ? symbol[type] : login[type]) -
                this.snapshotData[type];
            if (unit === "lot") {
                if (variation < 0) {
                    return variation.toFixed(2) + "d";
                } else if (variation > 0) {
                    return variation.toFixed(2) + "u";
                } else {
                    return variation.toFixed(2);
                }
            } else {
                if (this.snapshotData[type] == 0) {
                    return "N/A";
                }
                const percentage = (variation / this.snapshotData[type]) * 100;
                if (percentage < 0) {
                    return percentage.toFixed(2) + "%d";
                } else if (percentage > 0) {
                    return percentage.toFixed(2) + "%u";
                } else {
                    return percentage.toFixed(2) + "%";
                }
            }
        },
    },
};
</script>

<style>
.v-text-field__details {
    display: none;
}
</style>
