export function getLoginSnapshotData(query) {
    const mockData = {
        status: 200,
        data: {
            "long": 0.19,
            "short": 0.18,
            "net": 0.010000000000000009,
            "realized": 0,
            "unrealized": 4.74,
            "dailyNet": 4.74
        }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}


export function getSymbolSnapshotData(query) {
    const mockData = {
        status: 200,
        data: {
            "long": 78.22,
            "short": 92.04,
            "net": -13.820000000000007,
            "realized": 0,
            "unrealized": 0,
            "dailyNet": 0
        }
    };
    const returnVal = new Promise((resolve, _) => resolve(mockData));
    return returnVal;
}
